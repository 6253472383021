<template>
  <div
    id="city-page"
    class="d-flex flex-column justify-content-between h-100 page p-3"
  >
    <div
      v-if="selectLocation"
      class="position-absolute h-100 w-100 p-2 city-selector-wrapper flex-column d-flex justify-content-between"
    >
      <mdj-city-selector @input="onInput" @radius="onRadius" />
      <small class="d-block text-center mb-3 mt-4">
        <b-link
          class="text-center d-block text-muted"
          @click="selectLocation = false"
        >
          Retour
        </b-link>
      </small>
    </div>
    <div>
      <div class="my-3">
        <font-awesome-layers class="fa-5x">
          <font-awesome-icon icon="circle" class="text-primary" />
          <font-awesome-icon
            icon="map-marker-alt"
            class="text-white"
            transform="shrink-6"
          />
        </font-awesome-layers>
      </div>
      <h2 class="title">Restez connecté à votre ville</h2>
      <p class="mb-5">
        Choississez une ville pour voir ses restaurants, leurs Menus Du Jour et
        leurs Bons Plans
      </p>
    </div>
    <div>
      <btn-with-icon
        type="submit my-4"
        :icon="['fas', 'search']"
        variant="outline-secondary"
        block
        class="rounded-pill"
        :only-icon="false"
        @click="selectLocation = true"
      >
        {{ city || "Rechercher une ville" }}
      </btn-with-icon>
      <btn-with-icon
        :icon="['fas', 'chevron-right']"
        variant="primary"
        block
        :only-icon="false"
        :to="{ name: 'list' }"
      >
        Continuer
      </btn-with-icon>
    </div>
  </div>
</template>
<script>
import location from "@/mixins/location";
export default {
  mixins: [location],
  data() {
    return {
      loading: false,
      selectLocation: false,
    };
  },
};
</script>
